<template>
    
    <div class="bg-slate-800 p-2 border-b border-slate-700">
        <div class="flex flex-row gap-2 px-2 bg-slate-800 border-slate-700">
            <!-- rounded-md shadow-slate-950 shadow-sm -->
            <div class="w-18 mr-2 shrink-0 relative mt-6 max-ms:hidden">
                <div v-if="Data.user_data.user_pic && Data.user_data.user_pic != 'NO'">
                    <img :src="getProfilePic(Data.user_data.user_pic)" class="rounded-full w-16 h-16 border border-slate-600">
                </div>
                <div v-else>
                    <div class="bg-slate-700 rounded-full w-16 h-16 p-5 border border-slate-600 mx-auto">
                        <img src="@/assets/icon/nophoto.svg" class="w-5 h-5 opacity-40 mx-auto">
                    </div>
                </div>
                <router-link v-if="Data.user_data.user_vip==1" :to="{ name: 'vip' }" class="absolute right-0 bottom-0 bg-orange-700 text-orange-200 uppercase text-sm font-bold px-2 py-1 rounded-md"><img src="@/assets/icon/vip.svg" class="w-5 my-auto">VIP</router-link>
            </div>
            <div class=" w-8/12 flex flex-col mr-8">
                <div class="text-sm text-slate-500 uppercase">YOUR GANG</div>
                <!-- <div class="text-sm text-slate-500 uppercase">Gang</div> -->
                <div class="text-lg font-bold flex flex-row gap-2">
                    <div>{{ Data.user_data.user_name }}</div>
                    <router-link :to="{ name: 'settings' }" class="my-auto"><img src="@/assets/icon/edit-w.svg" class="w-4 h-4 opacity-30"></router-link>
                </div>
                <div class="text-sm flex flex-row gap-1">
                    <div class="shrink-0">Level <b>{{ Data.user_data.user_level }}</b></div>
                    <div class="shrink-0 text-sm text-slate-500 uppercase">EXP</div>
                    <div class="shrink-0 text-sm text-slate-400">{{ Data.user_data.user_exp }} / {{ Data.user_data.user_exp_next }}</div>
                </div>
                <!-- <div class="text-sm">{{ Data.user_data.user_gang }}</div> -->
                
                <div class="my-2 flex items-center bg-gray-200 h-2 rounded-md overflow-hidden border-slate-400 border-b border-r">
                    <div class="bg-gradient-to-b from-blue-500 to-blue-800 h-full" :style="{ width: (Data.user_data.user_exp_num / Data.user_data.user_exp_next_num) * 100 + '%' }"></div>
                </div>
                <!-- <div class="flex flex-row gap-1">
                    <div class="text-sm text-slate-500 uppercase">EXP</div>
                    <div class="text-sm text-slate-400">{{ Data.user_data.user_exp }} / {{ Data.user_data.user_exp_next }}</div>
                </div> -->

            </div>
            <!-- <div class=" w-1/3  flex flex-col mr-2">
                <div class="text-sm text-slate-500 uppercase">EXP</div>
                <div class="my-2 flex items-center bg-gray-200 h-2 rounded-md overflow-hidden border-slate-400 border-b border-r">
                    <div class="bg-gradient-to-b from-blue-500 to-blue-800 h-full" :style="{ width: (Data.user_data.user_exp / Data.user_data.user_exp_max) * 100 + '%' }"></div>
                </div>
                <div class="text-sm text-slate-400">{{ Data.user_data.user_exp }} / {{ Data.user_data.user_exp_max }}</div>
            </div> -->
            <div class=" w-4/12 flex flex-col shrink-0">
                <div class="text-sm text-slate-500 uppercase">Balance</div>
                <div class="w-full flex flex-row  shrink-0">
                        <div class="text-slate-100 mr-2 uppercase">
                            <img src="@/assets/pics/cash.png" class="w-5 my-auto">
                        </div>
                        <div class="text-base font-bold whitespace-nowrap">{{ Data.user_data.user_cash }}</div>
                </div>
                <div class="w-full flex flex-row mb-1  shrink-0 ">
                        <div class="text-sm ml-7 whitespace-nowrap">
                            <div v-if="Data.user_data.user_profit>0">
                                <span class="text-green-400">+{{ Data.user_data.user_profit }}</span> <span class="text-slate-500">/h</span>
                            </div>
                            <div v-else>
                                <span class="text-orange-400">{{ Data.user_data.user_profit }}</span>
                            </div>
                        </div>
                </div>
                <!-- <div class="w-full flex flex-row">
                        <div class="text-slate-100 mr-2 uppercase">
                            <img src="@/assets/icon/ton.svg" class="w-5 my-auto">
                        </div>
                        <div class="text-base font-bold">{{ Data.user_data.user_ton }}</div>
                </div> -->
                <router-link :to="{ name: 'guide-fight' }" class="text-sm text-slate-200 read-more w-fit whitespace-nowrap" >How to earn?</router-link>
                <!-- <router-link :to="{ name: 'profile' }" class="but-5 but-b flex flex-row w-fit"> -->
                <!-- <button @click="doUserProfile" class="but-5 but-c flex flex-row w-fit">

                        <img src="@/assets/icon/cash-b.svg" class="w-3 h-3 mr-2 my-auto max-xs:hidden">
                        <div v-if="Data.user_data.user_ton>0" class="">Claim</div>
                     <div v-else class="">Profile</div> 
                </button> -->
                <!-- </router-link> -->
            </div>
            
            <!-- Gang in the top 
            <div class=" w-1/4  flex flex-col mr-2">
                <div class="text-sm text-slate-500 uppercase">Gang</div>
                <div class=" mb-1">
                    <div class="font-bold">{{ Data.user_data.user_gang }}</div>
                    <div class="text-sm"> Gangsters</div>
                </div>
                <div class="but-5 but-c flex flex-row">
                    <img src="@/assets/icon/plus-b.svg" class="w-3 h-3 mr-2 my-auto">
                    <div class="">Invite</div>
                </div>
            </div>
            <div class=" w-1/4 flex flex-col">
                <div class="text-sm text-slate-500 uppercase">Hired</div>
                <div class=" mb-1">
                    <div class="font-bold">{{ Data.user_data.user_merc }}</div>
                    <div class="text-sm"> Mercenaries</div>
                </div>
                <div class="but-5 but-c flex flex-row">
                    <img src="@/assets/icon/cash-b.svg" class="w-3 h-3 mr-2 my-auto">
                    <div class="">Hire</div>
                </div>
            </div> -->
           
        </div>
        
    </div>  

</template>

<script>

  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
    },
    data() {
        return {
            userData: {},

        }
    },
    mounted() {
        this.userData = this.Data.user_data;
       
    },
}
</script>