<template>
<div class="bg-slate-900 bg-opacity-95 text-white md:w-[80%] md:shadow-2xl md:shadow-slate-950 min-h-screen md:max-w-[700px] w-full mx-auto md:border-l md:border-r md:border-slate-700">
<div v-if="isMiniApp">

    <div v-if="!noUser">

        <div v-if="!isError">

            <div v-if="!isLoading">

                <!-- MENU BURGER - also in Menu.vue <div @click="doMenu()" v-if="!openMenu"  class="cursor-pointer
                fixed bottom-12 right-0 shadow-2xl
                text-sm uppercase bg-gradient-to-b text-center from-slate-700 to-slate-800 h-16 border-t border-slate-600
                rounded-md hover:bg-slate-600
                flex flex-col items-center justify-center
                w-1/5 p-2 z-[49] mx-auto
                ">
                        <div class="m-2 h-7"><img src="@/assets/icon/menu.svg" class="w-6 h-6 mx-auto"></div>
                        <div>Menu</div>
                </div> -->


                <!-- Profile -->
                <div v-show="UserProfile" class="z-[98] bg-black bg-opacity-50 top-0 bottom-0 left-0 right-0 fixed"></div>
                <div v-show="UserProfile" class="z-[99] bg-slate-800 border border-slate-600 shadow-slate-950 shadow-2xl fixed p-3 top-3 right-3 left-3 rounded-md bottom-3 overflow-x-auto">
                    <div class="flex flex-row gap-3 mb-2">
                        <h2>Profile</h2>
                        <button class="ml-auto but-5 but-b mt-1" @click="doUserProfile">X</button>
                    </div>
                    <div class="flex flex-col gap-3">
                        <!-- <div class="">
                            <h5>User</h5>
                            <h1>{{ Data.user_data.user_name }}</h1>
                        </div> -->
                        <div class="border border-slate-700 rounded-md p-3">
                            <div class=" flex flex-col gap-2">
                                <h5>TON Wallet</h5>
                                <div class="text-sm mb-0">Connect TON crypto wallet to make purchases in the Shop and receive your share from the Treasury.
                                    <!-- and purchase Gems and Packs from the Store. -->
                                </div>
                                <div id="connect-button" class="ml-auto"></div>
                                <!-- TONWallet.Address = {{ TONWallet.Address }}
                                <div v-if="TONWallet.Address">
                                    <div class="flex flex-row gap-2 bg-orange-600 bg-opacity-25 rounded-md my-1 p-2 w-fit px-3">
                                        <img src="@/assets/icon/ton.svg" class="w-5">
                                        <div class="">{{ TONWallet.Address }}</div>
                                    </div>
                                    <div class="w-fit text-slate-400 text-sm">
                                        <button class="read-more" @click="delWallet()">Disconnect</button>
                                    </div>
                                </div>
                                <div v-else>
                                    <button class="but-3 but-c gap-2 w-fit mb-2"><img src="@/assets/icon/ton.svg" class="w-5" @click="connectWallet()">Connect Wallet</button>
                                </div> -->
                                <!-- Info -->
                                <div v-if="!TONWallet.Address">
                                    <div class="text-slate-400 text-sm flex flex-row gap-3">
                                        <div class="w-4 mt-1 shrink-0"><img src="@/assets/icon/info.svg" class="w-4"></div>
                                        <div class="text-sm">
                                            <div>The most convenient way to use TON Spaces in the Wallet Telegram app. Click the "Connect Wallet" button and choose "Telegram Wallet" app.</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div>
                                        <router-link @click.native="closeUserProfile()" :to="{ name: 'shop' }" class="but-4 but-a">Open <img src="@/assets/pics/gem.png" class="w-5"> Shop</router-link>
                                    </div>
                                </div>
                                <!-- <div class="text-slate-400 text-sm flex flex-row gap-3 pb-2">
                                    <div class="w-4 mt-1 shrink-0"></div>
                                    <div>
                                        <div>We also recommend using TON Keeper or other TON wallets.</div>
                                        <router-link :to="{ name: 'guide-prop' }" class="read-more">Read More</router-link>
                                    </div>
                                </div> -->
                                
                                <!-- <div>Connect your TON wallet to puchase Gems in the shop and receive weekly payouts in TON.</div> -->
                                <!-- <div class="flex flex-row gap-3 p-3">
                                    <div id="ton-connect"></div>
                                    <button @click="connectWallet()" class="but-4 but-c">Connect</button>
                                </div> -->
                                <!-- <div class="but-3 but-c gap-2 w-fit"><img src="@/assets/icon/ton.svg" class="w-5">Connect Wallet</div> -->
                            </div>
                        </div>

                        <div class="border border-slate-700 rounded-md p-3">
                            <div class=" flex flex-col gap-2">
                                <h5>Boba Wallet</h5>
                                <div class="text-sm mb-0">DON TON uses a digital wallet on Boba BNB blockchain to record in-game actions and Cash transaction results.</div>
                                <div class="flex flex-row gap-2 bg-slate-700 rounded-md my-1 p-2 w-fit px-3">
                                    <!-- <img src="@/assets/icon/boba.svg" class="w-5"> -->
                                    <div class="">{{ formatWallet(Data.user_data.user_boba) }}</div>
                                </div>
                                <div>You can track all the actions on the blockchain:</div>
                                <a :href="`https://bnb.bobascan.com/address/${Data.user_data.user_boba}`" target="_blank" class="w-fit but-5 but-c">Blockscan</a>

                            </div>
                        </div>

                        <div class="border border-slate-700 rounded-md p-3">
                            <div class=" flex flex-col gap-2">
                                <h5>E-mail</h5>

                                <div v-if="!doChange" class="flex flex-col gap-2">
                                    <div v-if="Data.user_data.user_mail">
                                        <div class="text-sm mb-3">Your E-mail address:</div>
                                        <div class="font-mono border border-slate-600 rounded-md p-2 px-3 flex flex-row text-base mb-1">
                                            <div>{{ Data.user_data.user_mail }}</div>
                                            <button class="ml-auto but-5 but-b gap-2 w-fit opacity-40" @click="mailChange()">Change</button>
                                        </div>
                                        <div class="text-sm mt-1 text-slate-400 mb-1">Unsubscribe at any time using the link in the letter.</div>
                                    </div>
                                    <div v-else>
                                        <div class="text-sm mb-3">Subscribe to bi-weekly newsletter not to miss important announcements.</div>
                                        <!-- <input v-model="eMail" class="text-black p-2 rounded-md " /> -->
                                        <button class="but-3 but-a gap-2 w-fit" @click="mailChange()">Enter E-mail</button>
                                        <!-- <div class="text-sm text-slate-400 mt-2">Claim <b>2500</b> <img src="@/assets/pics/cash.png" class="w-5 inline-block"> Cash in Achievements after e-mail confirmation.</div> -->
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="inAction">
                                        <div class="spinner mx-auto mb-2"></div>
                                        <div class="mb-2 text-center">Sending request...</div>
                                    </div>
                                    <div v-else-if="gotError">
                                        <div v-if="gotErrorCode==1" class="text-yellow-200">{{ gotError }}</div>
                                        <div v-else class="text-yellow-200">{{ gotError }}</div>
                                        <button @click="closeAction()" class="but-4 but-b mt-2">Retry</button>
                                    </div>
                                    <div v-else-if="actionDone">
                                        <div class="text-green-200">E-mail saved successfully.</div>
                                        <button @click="mailChange()" class="but-4 but-a mt-2">Close</button>
                                    </div>
                                    <div v-else class="flex flex-col gap-2">
                                        <div class="text-sm mb-1">Your e-mail adress:</div>
                                        <input type="text" v-model="eMail" class="text-black text-lg rounded-md border border-slate-500 p-2">
                                        <div class="text-sm mb-1 text-slate-400">We will send the link to confirm your e-mail.</div>
                                        <button class="but-3 but-a w-fit mb-1" @click="mailSave()">Save</button>
                                    </div>                    
                                </div>
                            </div>
                        </div>

                        <div class="border border-slate-700 rounded-md p-3">
                            <div class=" flex flex-col gap-2">
                                <h5>In-game Balance</h5>
                                <div class="text-sm">TON is earned from Treasury shares for <img src="@/assets/icon/cup.svg" class="w-5 h-5 inline-block mr-2">Cups in weekly seasons, ready to be withdrawn to your TON wallet.</div>
                                <div class="flex flex-row gap-2 bg-slate-700 rounded-md my-1 p-2 w-fit px-3">
                                    <img src="@/assets/icon/ton.svg" class="w-5">
                                    <div class="">{{ Data.user_data.user_ton }}</div>
                                </div>
                                <div v-if="TONWallet.Address">
                                    <div class="text-slate-400 text-sm flex flex-row gap-3">
                                        <div class="w-4 mt-1 shrink-0"><img src="@/assets/icon/info.svg" class="w-4"></div>
                                        <div>
                                            <div>
                                                TON Treasury distribution will start on Stage II (Beta) of the project development.
                                                Subscribe to project news channel to stay informed on the announcements.
                                            </div>
                                            <a href="https://t.me/don_ton_news" class="mt-2 but-5 but-a w-fit" @click="closeUserProfile()">
                                                <img src="@/assets/icon/social/t.svg" class="w-5 mr-2 inline-block">
                                                News Channel
                                            </a>
                                        </div>
                                    </div>
                                    <!-- 
                                    <div class="but-3 but-c gap-2 w-fit"><img src="@/assets/icon/ton.svg" class="w-5">Withdraw</div>
                                    <div class="text-sm text-slate-400">Please connect your wallet first.</div> 
                                    -->
                                    <!-- <div class="but-3 but-c gap-2 w-fit"><img src="@/assets/icon/ton.svg" class="w-5">Withdraw</div> -->
                                    </div>
                                <div v-else class="text-sm text-slate-400">Please connect your wallet to enable withdrawal feature.</div>
                            </div> 
                        </div>

                        <div class="border border-slate-700 rounded-md p-3">
                            <h5>Settings</h5>
                            <div class="mb-3 mt-1 text-sm">Fine tune your DON TON experience:</div>
                            <router-link :to="{ name: 'settings' }" @click.native="closeUserProfile()" class="but-4 but-c text-white">
                                <img src="@/assets/icon/set.svg" class="w-5 mr-2 inline-block">
                                Settings
                            </router-link>
                        </div>

                    </div>
                </div>

                <!-- The App -->

                <ListMenu v-if="openMenu" 
                    @doMenu="doMenu" 
                    @doUserProfile="doUserProfile" 
                    @doTutorial="doTutorial"
                    @closeTutorial="closeTutorial"
                    :TutorialShow="TutorialShow"
                    :TutorialStep="TutorialStep" 
                    :Data="Data" 
                    :DEBUG="DEBUG" 
                    @doDEBUG="doDEBUG" 
                />

                <MainMenu v-if="!openMenu"  
                    @doMenu="doMenu" 
                    @doUserProfile="doUserProfile" 
                    @doTutorial="doTutorial" 
                    @closeTutorial="closeTutorial"
                    :TutorialShow="TutorialShow" 
                    :TutorialStep="TutorialStep" 
                    @updateFight="updateFight"
                    :Data="Data" 
                    :DEBUG="DEBUG" 
                />

                <!-- <UserMenu v-show="userMenu" 
                    :Data="Data" 
                    :DEBUG="DEBUG" 
                    @doUserMenu="doUserMenu" 
                    :TON="TON" 
                    @connectWallet="connectWallet" 
                    :TONConnection="TONConnection" 
                /> -->

                <Tutorial v-if="TutorialShow" 
                    :TutorialShow="TutorialShow" 
                    :TutorialStep="TutorialStep" 
                    @closeTutorial="closeTutorial" 
                    :Data="Data" 
                    :DEBUG="DEBUG" 
                />

                <div v-if="!openMenu">
                    
                    <TopPanel 
                        @doMenu="doMenu" 
                        @doUserProfile="doUserProfile"
                        @doTutorial="doTutorial"
                        @closeTutorial="closeTutorial"
                        :updatingData="updatingData"
                        :TutorialShow="TutorialShow"
                        :TutorialStep="TutorialStep" 
                        :Data="Data" 
                        :DEBUG="DEBUG" 
                    />

                    <!-- <BlockGangInfo :Data="Data" :DEBUG="DEBUG" /> -->

                    <PanelHP v-if="showHP" 
                        :Data="Data" 
                        :DEBUG="DEBUG" 
                    />

                    <!-- <Header v-if="showCity" 
                        :Data="Data" 
                        :DEBUG="DEBUG" 
                    /> -->
                   
                    <!-- <div class="fixed z-50 bottom-20 right-5 w-10 rounded-full h-10 bg-blue-500 p-2 shadow-2xl" @click="doMenu()"><img src="@/assets/icon/menu.svg" class=""></div> -->

                    <ModalOnboard v-if="Onboard" 
                        @closeModal="closeModal"
                        :Data="Data"
                        :DEBUG="DEBUG"
                    />

                    <ModalPromo1 v-if="Promo1" 
                        @closeModal="closeModal"
                        :Data="Data"
                        :DEBUG="DEBUG"
                    />

                    <NewLevel v-if="LevelUp" 
                        :Data="Data"
                        :DEBUG="DEBUG" 
                        @closeLevelUp="closeLevelUp" 
                    />
                    <NewAchieve v-if="Achieve" 
                        :Data="Data" 
                        :DEBUG="DEBUG" 
                        @closeAchieve="closeAchieve" 
                    />
                        
                    <div class="pb-16">
                        <router-view
                            :TONConnection="TONConnection" 
                            @updateData="updateData" 
                            @doUserProfile="doUserProfile" 
                            @doTutorial="doTutorial"
                            @closeTutorial="closeTutorial"
                            :TutorialShow="TutorialShow" 
                            :TutorialStep="TutorialStep" 
                            :telegramData="telegramData" 
                            :Data="Data" 
                            :initData="initData" 
                            :loadedData="loadedData" 
                            :randReload="randReload"
                            :lastUpdateTime="lastUpdateTime"
                            :DEBUG="DEBUG" 
                        />
                        <!-- @skipTutorial="skipTutorial" -->

                        <div class="text-slate-500 text-sm p-3 text-[6px]" v-if="DEBUG">
                           {{ debug_info }}
                        </div>
                        
                    </div>

                </div>

            </div>

            <div v-else class="bg-slate-900 h-screen flex flex-row justify-center text-center relative">

                <div class="bg-[url('@/assets/bg/city1.jpg')] bg-cover opacity-15 absolute inset-0"></div>
                
                <div class="my-auto text-center">
                    <div class="mb-4"><img src="@/assets/logo/dt.png" class="w-32 mx-auto"></div>
                    <div class="mb-8 uppercase text-zinc-400 text-sm">
                            <div>
                                <div v-if="loaderText === 1">Fight for $DON</div>
                                <div v-if="loaderText === 2">Fight for $DON</div>
                                <!-- <div v-if="loaderText === 2">Massively Multiplayer Web3 Game</div> -->
                                <div v-if="loaderText === 3">Loot Some $DON Today</div>
                                <div v-if="loaderText === 4">Loot Some $DON Today</div>
                                <!-- <div v-if="loaderText === 4">$DON</div> -->
                                <!-- <div v-if="loaderText === 4">A Social Experiment</div> -->
                                <div v-if="loaderText === 5">Your Mafia Gang in Telegram</div> 
                            </div>
                    </div> 

                    <div class="my-5">
                        <img src="@/assets/pics/treasury.png" class="w-64 mx-auto">
                        <!--w-[438px] h-[375px]-->
                        <div class="bg-slate-700 bg-opacity-25 rounded-md p-3 mt-[-15px]">
                            <div class="text-slate-200 flex-grow">
                                <div class="text-sm font-light mt-[-3px] text-yellow-200 uppercase">Treasury Airdrop</div>

                                <div class="text-xl font-extrabold mb-0 p-0"><img src="@/assets/pics/don.png" class="w-6 shadow-sm mb-1 mr-3 inline-block"> 
                                    <span style=" letter-spacing: -0.05em;">1,000,000,000 $DON</span></div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4 spinner mx-auto"></div>
                    <div class="mb-4 uppercase text-zinc-200">Loading</div> 
                </div>
                
            </div>

        </div>
        <div v-else>

            <div class="pt-10">
                <img src="@/assets/icon/warn-o.svg" class="w-14 h-14 mx-auto">
                <p class="p-5  text-center">We have encountered an <span @click="doDEBUG()">error</span> while processing miniapp. Please try again later or contact us in the support group:</p>
                <div class="w-full text-center">
                    <a href="http://t.me/don_ton_chat" class="mt-2 but-4 but-a w-fit mx-auto">
                        <img src="@/assets/icon/social/t.svg" class="w-5 mr-2 inline-block">
                        Open the Chat
                    </a>
                </div>
                <p class="p-5  text-center">The team is here to support you!</p>

                <p class="border-t border-slate-600 p-5 text-sm text-center">Sending <b>/start</b> or <b>/retry</b> command to the bot may help in some situations.</p>
                <p v-if="DEBUG" class="p-5 text-sm text-yellow-200 text-center">Error code: {{ isErrorText }}</p>
            </div>
     </div>

        <!--<div class="debug" v-if="DEBUG">WebAppData = {{ WebAppData }}</div><hr>-->
        <!--<div class="debug" v-if="DEBUG">initData = {{ initData }}</div><hr>-->
        <div class="debug" v-if="DEBUG">Data = {{ Data }}</div>
        <div class="debug" v-if="DEBUG">telegramData = {{ telegramData }}</div>
       
    </div>
    <!-- / if nouser --><div v-else>

        <div class="pt-10">
            <img src="@/assets/logo/dt.png" class="w-24 mx-auto mb-2">
            <p class="px-10 my-4  text-center">Play DON TON and attack rival gangs by starting the bot:</p>
            <div class="w-full text-center">
                <a href="http://t.me/don_ton_bot" class="mt-2 but-3 but-a w-fit mx-auto">
                    <img src="@/assets/icon/social/t.svg" class="w-5 mr-2 inline-block">
                    Start The Bot
                </a>
            </div>
            <div class="mt-5 text-sm text-slate-400 text-center">DON TON is a massively multiplayer game on The Open Network.</div>
            <img src="@/assets/spot1.jpg" class="w-[300px] rounded-xl mx-auto mt-4 border border-slate-800">
            <p class="pt-4 px-10 text-sm text-center">Build the most powerful mafia gang in Telegram, 
                <img src="@/assets/set/gun.svg" class="w-5 inline-block"> attack rival gangs for loot in 
                <img src="@/assets/pics/cash.png" class="w-5 h-5 inline-block"> Cash, invest in properties and earn 
                <img src="@/assets/icon/cup.svg" class="w-4 h-4 inline-block"> Cups to claim Toncoin and $USDT from the Treasury!
            </p>

                <div class="w-full text-center mb-5 mt-2">
                <a href="http://t.me/don_ton_bot" class="mt-2 but-3 but-a w-fit mx-auto">
                    <img src="@/assets/icon/social/t.svg" class="w-5 mr-2 inline-block">
                    Play Now
                </a>
            </div>

            <div class="border-t border-slate-600 p-5 text-sm text-center">
                Learn more about the DON TON game:
                <div class="mt-4"><a href="https://donton.net" class="but-c but-4" target="_blank">Official Website</a></div>
            </div>
        </div>

    </div><!-- / if nouser -->
</div>
<!-- if miniapp / --><div v-else>
    <div class="pt-10">
        <img src="@/assets/logo/dt.png" class="w-32 mx-auto">
        <p class="p-5  text-center">This is a Telegram Mini App that can not be launched outside Telegram.</p>
        <div class="w-full text-center">
            <a href="http://t.me/don_ton_chat" class="mt-2 but-4 but-a w-fit mx-auto">
                <img src="@/assets/icon/social/t.svg" class="w-5 mr-2 inline-block">
                Join Telegram Group
            </a>
        </div>
        <div class="p-5 text-sm text-slate-400 text-center">The team is here to support you!</div>

        <div class="border-t border-slate-600 p-5 text-sm text-center">
            Learn more about the DON TON game:
            <div class="mt-4"><a href="https://donton.net" class="but-c but-3">Official Website</a></div>
        </div>
    </div>
    
</div><!-- / if miniapp -->
</div></template>

<script>
import * as TON_CONNECT_UI from '@tonconnect/ui';

import axios from 'axios';
// import { watch } from 'vue'
// import { useRouter } from 'vue-router'

import PanelHP from '@/components/PanelHP.vue';
import MainMenu from '@/components/MainMenu.vue';
import ListMenu from '@/components/Menu.vue';
import NewLevel from '@/components/NewLevel.vue';
import NewAchieve from '@/components/NewAchieve.vue';
import TopPanel from '@/components/TopPanel.vue';
import Tutorial from '@/components/Tutorial.vue';
import ModalPromo1 from '@/modal/Promo1Stars.vue';
import BlockGangInfo from '@/blocks/GangInfo.vue';
import Header from '@/blocks/Header.vue';
import ModalOnboard from '@/modal/Onboard.vue';

export default {
    name: 'App',
    components: {
        PanelHP,
        MainMenu,
        ListMenu,
        NewLevel,
        NewAchieve,
        TopPanel,
        Tutorial,
        ModalPromo1,
        BlockGangInfo,
        ModalOnboard,
        Header,
    },
    watch: {
        '$route'() {
            this.checkRoute(); // for HP panel
        },
        UserProfile(value) {
            if (value) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    },
  data() {
    return {
        TONConnection: null,
        TONWallet: {
            Address: '',
            Public: '',
            Data: null,
        },

        DEBUG: false,
        initData: '',
        Data: {},

        isMiniApp: false,
        isLoading: true,
        loaderText: 0,
        isError: false,
        isErrorText: '',

        debug_info: '',

        userData: {},
        showHP: false,
        showCity: false,
        loadedData: false,

        LevelUp: false,
        Achieve: false,

        clientId: '',
        sessionId: '',

        // email
        eMail: '',
        doChange: false,
        actionDone: false,
        actionResult: {},
        gotError: '',
        gotErrorCode: 0,
        inAction: false,

        noUser: false,

        TutorialShow: false,
        TutorialStep: 0,

        teleData: {},
        telegramData: {
            initData: '',
            initDataUnsafe: {},
            version: '',
            platform: '',
            colorScheme: '',
            themeParams: {},
            isExpanded: false,
            viewportHeight: 0,
            viewportStableHeight: 0,
            headerColor: '',
            backgroundColor: '',
            isClosingConfirmationEnabled: false,
        },
        randReload: 0,
        Promo1: false,
        Promo2: false,
        updatingData: false,
        Onboard: true,

        openMenu: false,
        UserProfile: false,
        lastUpdateTime: null,
    };
  },
  mounted() {

    if(process.env.VUE_APP_DEBUG==1) this.DEBUG=true;

    this.loaderText = Math.floor(Math.random() * 5) + 1;

    this.WebAppData = window.Telegram.WebApp; // ???

    // alert('window.Telegram = '+JSON.stringify(window.Telegram));
    // alert('window.Telegram.WebApp = '+JSON.stringify(window.Telegram.WebApp));
    // window.Telegram = {"WebApp":{"initData":"query_id=[...]":false}}

    if ( window.Telegram.WebApp ) {

        this.isMiniApp = true;

        Object.assign(this.telegramData, {
            initData: window.Telegram.WebApp.initData,
            initDataUnsafe: window.Telegram.WebApp.initDataUnsafe,
            version: window.Telegram.WebApp.version,
            platform: window.Telegram.WebApp.platform,
            colorScheme: window.Telegram.WebApp.colorScheme,
            themeParams: window.Telegram.WebApp.themeParams,
            isExpanded: window.Telegram.WebApp.isExpanded,
            viewportHeight: window.Telegram.WebApp.viewportHeight,
            viewportStableHeight: window.Telegram.WebApp.viewportStableHeight,
            headerColor: window.Telegram.WebApp.headerColor,
            backgroundColor: window.Telegram.WebApp.backgroundColor,
            isClosingConfirmationEnabled: window.Telegram.WebApp.isClosingConfirmationEnabled,
        });
            
        this.initData = window.Telegram.WebApp.initData;
        this.teleData.platform = this.telegramData.platform;
        this.teleData.version = this.telegramData.version;
        this.teleData.initDataUnsafe = this.telegramData.initDataUnsafe;

        this.updateData().then(() => {
            // alert('Data loaded');
            // Not run earlier then 30 sec
            // alert('tutorial='+this.Data.user_data.user_tutorial);

            // this.userData = this.Data.user_data;
            // this.cityImage = require(`@/assets/bg/city${this.Data.user_data.user_city}.jpg`);

            if(this.Data.user_data) {
                if(this.Data.user_data.user_tutorial>=0 && this.Data.user_data.user_tutorial<10) {
                    this.TutorialShow = true;
                    // alert('tutorial='+this.Data.user_data.user_tutorial);
                    this.TutorialStep = this.Data.user_data.user_tutorial;
                }

                if(this.Data.user_data.tonton) {
                    // Pass GA Event
                    console.log('tonton user');
                    this.debug_info = 'tt';
                    if (window.dataLayer) {
                        console.log('ga enabled');
                        this.debug_info = this.debug_info + ' ga'; 
                    }
                    const user_from=this.Data.user_from || '';
                    // this.debug_info = 'tt, ga push for '+this.teleData.initDataUnsafe.user.id+' from '+user_from;
                    console.log('push event for '+this.teleData.initDataUnsafe.user.id+' from '+user_from);
                    window.dataLayer?.push({
                        'event': 'Pageview',
                        // 'pagePath': '',
                        'pageTitle': 'Game Open',
                        'userId': this.teleData.initDataUnsafe.user.id,
                        'source': user_from
                    });
                    
                    this.getGoogleAnalyticsIds();

                }
            }

            this.initTonConnect();
            // this.checkInitialConnection();
            
            // /// // this.connectTON();

        });

        // DO THIS IF NOT LOCAL MOCK
        // Telegram.WebApp.ready();
        // alert('Telegram Ready');

        // .then(

        try {
            window.Telegram.WebApp.enableClosingConfirmation();
            window.Telegram.WebApp.disableVerticalSwipes();
            window.Telegram.WebApp.expand(); // Telegram.WebApp.expand();
        } catch (error) {
            // console.error('Error expanding Telegram WebApp:', error);
        }

        //     // console.log('Validation response:', response.data);
        // );

    } else {
       // console.log('Telegram WebApp is not loaded');
       this.isMiniApp = false;
        
    //    if (window.dataLayer) {
    //         console.log('ga enabled');
            window.dataLayer?.push({
                'event': 'Pageview',
                // 'pagePath': '',
                'pageTitle': 'Not Miniapp',
            });
        // }

    }
    this.checkRoute();

  },
  methods: {

    getGoogleAnalyticsIds() {
        // Read the _ga cookie
        const gaCookie = this.getCookie('_ga');
        if (gaCookie) {
            // The client_id is the last two parts of the _ga cookie
            const parts = gaCookie.split('.');
            this.clientId = parts.slice(2).join('.');
        }
    
        // Replace YOUR_MEASUREMENT_ID with your actual Measurement ID
        const sessionCookie = this.getCookie('_ga_G-ELZFTQDCVV');
        if (sessionCookie) {
            // The session_id is the value of the _ga_<measurement_id> cookie
            this.sessionId = sessionCookie.split('.').pop();
        }
    
        console.log('Client ID:', this.clientId);
        console.log('Session ID:', this.sessionId);
        const clientId = this.clientId || '';
        const sessionId = this.sessionId || '';

        this.debug_info = this.debug_info + sessionId; // clientId + ' / ' + 

        // alert('Client ID: ' + this.clientId + '\nSession ID: ' + this.sessionId);

        axios.post(process.env.VUE_APP_API_URL+'settings/ga', {
            initData: this.initData,
            request: { cookie: clientId+"|"+sessionId },
        });

    },

    // WALLET

    initTonConnect() {

        try {

            // alert('initTonConnect');
            this.TONConnection = new TON_CONNECT_UI.TonConnectUI({
                manifestUrl: 'https://app.donton.net/ton-manifest.json',
                buttonRootId: 'connect-button',
            });

            // WILL RETURN WALLET INFO WHEN CONNECTED TO THE PAGE //

            this.TONConnection.onStatusChange((walletInfo) => {

                // we have a wallet
                // alert('on status change! '+JSON.stringify(walletInfo));

                this.TONWallet.Address = '';
                this.TONWallet.Public = '';
                this.TONWallet.Data = null;

                if(walletInfo) {
                    const wallet=walletInfo.account.address;
                    if (wallet) {

                        this.TONWallet.Address = walletInfo.account.address;
                        this.TONWallet.Public = walletInfo.account.publicKey;
                        this.TONWallet.Data = {
                            'chain': walletInfo.account.chain,
                            'walletStateInit': walletInfo.account.walletStateInit,
                            'wallet': walletInfo.name+' ('+walletInfo.appName+')',
                            'device': JSON.stringify(walletInfo.device),
                        };
                        
                        this.saveWallet(this.TONWallet.Address,this.TONWallet.Public,JSON.stringify(this.TONWallet.Data));

                    } 
                }
            });

            // this.TONConnection.onStatusChange((walletInfo) => {
            //     // alert('on status change '+alert(JSON.stringify(walletInfo)));
            //     this.TONWallet.Address = '';
            //     if(walletInfo) {
            //         const wallet=walletInfo.account.address;
            //         if (wallet) {
            //             this.TONWallet.Address = wallet;
            //         }
            //     }
            // });

            // this.TONConnection.onModalStateChange((state) => {
            //     if (state.status === 'closed' && state.closeReason) {
            //     console.log('Modal closed because:', state.closeReason);
            //     }
            // });

        } catch (error) {
            if (!error.message.includes('connect-button')) {
                console.log('initTonConnect Error: '+error);
            } else {
                console.log('initTonConnect Error: '+error);
            }
            // 1 - connect-button element not found ?
            // this.initError = 'Error initializing TON Connect UI';
        }

    },

    // async checkTonWalletConnection() {
    //     try {
    //     const address = await this.TONConnection.getAddress();
    //     this.wallet = address;
    //     } catch (error) {
    //     // alert('No active wallet connection: ' + error)
    //     console.error('No active wallet connection', error);
    //     this.wallet = '';
    //     }
    // },

    // checkTonConnectInitialization() {
    //     if (this.TONConnection) {
    //         alert('TON Connect UI initialized successfully');
    //     } else {
    //         alert('TON Connect UI failed to initialize');
    //     }
    // },

        // OLD ?

    // connectTON() {

        // try {
      
        //     this.TONConnection = new TON_CONNECT_UI.TonConnectUI({
        //         manifestUrl: 'https://app.donton.net/ton-manifest.json',
        //         buttonRootId: 'ton-connect'
        //     });

        //     this.TONConnection.uiOptions = {
        //         twaReturnUrl: 'https://app.donton.net/'
        //     };

        //     // this.TONConnection.uiOptions = {
        //     //     twaReturnUrl: 'https://app.donton.net/',
        //     //     uiPreferences: {
        //     //         theme: THEME.DARK
        //     //     }
        //     // };

        //     // this.checkTonConnectInitialization();
        
        // } catch (error) {
        //     // alert('Error initializing TON Connect UI = '+error);
        //     // this.initError = 'Error initializing TON Connect UI';
        // }

    // },

    // checkTonConnectInitialization() {
    //     if (this.TONConnection) {
    //         alert('TON Connect UI initialized successfully');
    //     } else {
    //         alert('TON Connect UI failed to initialize');
    //     }
    // },

    // async getWallets() {
    //     // const wallets = await this.TONConnection.getWallets(); // list of supported software wallets

    //     const currentState = this.TONConnection.singleWalletModalState;

    //     alert('currentState = ' + currentState);

    //     const currentWallet = this.TONConnection.wallet;
    //     const currentWalletInfo = this.TONConnection.walletInfo;
    //     const currentAccount = this.TONConnection.account;
    //     const currentIsConnectedStatus = this.TONConnection.connected;

    //     alert('currentWallet = ' + currentWallet);
    //     alert('currentWalletInfo = ' + currentWalletInfo);

    //     this.TON.data = {
    //         currentState: currentState,
    //         currentWallet: currentWallet,
    //         currentWalletInfo: currentWalletInfo,
    //         currentAccount: currentAccount,
    //         currentIsConnectedStatus: currentIsConnectedStatus,
    //     };

    // },

    updateData() {
        return new Promise((resolve, reject) => {

            // const now = Date.now();
            // if (this.lastUpdateTime && now - this.lastUpdateTime < 10 * 1000) { // 5 sec
            //     alert('blocked');
            //     return 0;
            //     // return Promise.reject(new Error('UpdateData called too frequently'));
            // }
            this.lastUpdateTime = Date.now();
            this.updatingData = true;

            if(this.initData.length>10) { 

                axios.post(process.env.VUE_APP_API_URL+'', {
                    initData: this.initData,
                    teleData: this.teleData,
                }).then((response) => {

                    // alert('# Data loaded: '+JSON.stringify(response.data));

                    if(response.data.status && response.data.status=='error') { 

                        if(response.data.no_user && response.data.no_user==1) {
                            this.noUser = true;
                        } else { 
                            this.isError = true;
                            if(response.data.message) this.isErrorText = response.data.message;
                            else this.isErrorText = 'API connection error.';
                            // console.error('Error API:', error);
                        }
                        this.isLoading = false;
                        this.updatingData = false;
                        resolve();
                        
                    } else {

                        this.Data = response.data.data;

                        // alert ('THIS DATA user_data ## '+JSON.stringify(this.Data.user_data));

                        this.userData = this.Data.user_data;
                        this.loadedData = true;
                        this.isError = false;
                        this.isErrorText= '';

                        if(this.Data.user_data.levelup==1) {
                            if(this.$route.name!='levelup')
                            this.LevelUp = true;
                        }

                        if(this.Data.user_data.achieve>0) {
                            if(this.$route.name!='achieve')
                            this.Achieve = true;
                        }

                        // if(this.Data.promo && this.Data.promo['1']) 
                        // {
                        //     this.showPromo(1);
                        // }
                        this.showPromo(1);

                        this.isLoading = false; // !!
                        this.updatingData = false;
                        resolve();

                    }

                }).catch((error) => {
                    this.isError = true;
                    this.isErrorText = 'ERROR = ' + error;
                    // console.error('Error posting data:', error);
                    this.updatingData = false;
                    reject();
                });

            } else {
                console.log('No initData provided.');
                this.isMiniApp = false;
                reject();
            }
        });

    },

    showPromo(promo) {

        axios.post(process.env.VUE_APP_API_URL+'promo_show', {
            initData: this.initData,
        }).then((response) => {
            // const res1=response.data;
            // alert('json='+JSON.stringify(response.data.promo1));
            if(response.data && response.data.promo1==1) this.Promo1 = true;
            
        }).catch((error) => {
            // console.error('Error posting data:', error);
        });

    },

    saveWallet() {

        if(!this.TONWallet) return;

        this.inAction = true;
        axios.post(process.env.VUE_APP_API_URL+'wallet/save', {
            initData: this.initData,
            request: { addr: this.TONWallet.Address, public: this.TONWallet.Public, data: this.TONWallet.Data },
        }).then((response) => {
            // alert(JSON.stringify(response.data));
            if(response.data.status === 'error') {
                // this.gotError = response.data.message;
                // this.gotErrorCode = response.data.error_code;
            } else {
                // this.actionDone = true;
                // this.actionResult = response.data;
                // if(this.actionResult.result && this.actionResult.result>0) this.$emit('updateData');
            }
            this.inAction = false;
        }).catch((error) => {
            this.gotError = error;
            console.error('Error:', error);
            this.inAction = false;
        });

    },

    mailChange() {
        this.closeAction();
        this.doChange = !this.doChange;
    },

    mailSave() {
        this.inAction = true;

        if(this.Data.user_data.user_name == this.gangName) {
            this.inAction = false;
            this.gotError = 'The e-mail is the same as the current one.';
            return;
        }
        axios.post(process.env.VUE_APP_API_URL+'settings/mail', {
            initData: this.initData,
            request: { email: this.eMail },
        }).then((response) => {
            // alert(JSON.stringify(response.data));
            if(response.data.status === 'error') {
                this.gotError = response.data.message;
                this.gotErrorCode = response.data.error_code;
            } else {
                this.actionDone = true;
                this.actionResult = response.data;
                this.Data.user_data.user_mail = this.eMail;
                // if(this.actionResult.result && this.actionResult.result>0) this.$emit('updateData');
            }
            this.inAction = false;
        }).catch((error) => {
            this.gotError = error;
            console.error('Error:', error);
            this.inAction = false;
        });

    },
    closeAction() {
        this.actionDone = false;
        this.actionResult = {};
        this.gotError = '';
        this.inAction = false;
    },

    updateFight() {
        this.randReload=Math.floor(Math.random() * 1000);
    },

    checkRoute() {// Open the share dialog

        if(
         this.$route.name=='home' ||
         this.$route.name=='restore' ||
         this.$route.name=='fight' || this.$route.name=='button' || this.$route.name=='code' || this.$route.name=='attack' ||
         this.$route.name=='jobs' || this.$route.name=='missions' || this.$route.name=='tasks' || this.$route.name=='achieve'
        ) this.showHP=true;
        else this.showHP=false;

        if(
        //  this.$route.name=='home' ||
        //  this.$route.name=='estate' ||
         this.$route.name=='button' || // this.$route.name=='code' || this.$route.name=='attack' ||
        //  this.$route.name=='jobs' || this.$route.name=='missions'
        this.$route.name=='fight'
        ) this.showCity=true;
        else this.showCity=false;
        
    },

    doTutorial(step) {
      if(this.TutorialShow) {
        // alert('doTutorial for step' +step ); //!//
        this.TutorialStep=step;
        if(step==10) this.TutorialShow=false;
        axios.post(process.env.VUE_APP_API_URL+'tutorial', {
            initData: this.initData,
            request: { step: step },
        }).then((response) => {
            // alert('SetStep result = '+JSON.stringify(response.data));
            this.Data.user_data.user_tutorial = step;
        }).catch((error) => {
            // alert('error'+error);
            // console.error('Error posting data:', error);
        });
      }
    },

    // skipTutorial(step) {

    //     axios.post(process.env.VUE_APP_API_URL+'tutorial/skip', {
    //         initData: this.initData,
    //         request: { step: step },
    //     }).then((response) => {
    //         alert(JSON.stringify(response.data));
    //         this.TutorialShow=false;
    //         this.Data.user_data.user_tutorial = -1;
    //     }).catch((error) => {
    //         // console.error('Error posting data:', error);
    //     });
    // },

    closeTutorial() {
        this.TutorialShow=false;
    },

    doMenu () {
        this.openMenu = !this.openMenu;
    },
    doUserProfile () {
        this.UserProfile = !this.UserProfile;
    },
    closeUserProfile() {
        this.UserProfile = false;
    },
    closeLevelUp () {
        this.LevelUp = false;
    },
    closeAchieve () {
        this.Achieve = false;
    },
    closeModal () {
        this.Promo1 = false;
        this.Onboard = false;
        // this.Promo2 = false;
    },


    
    // async checkWallet() {
    //   try {
    //     const connectedWallet = await this.TONConnection.connectWallet();
    //     console.log(connectedWallet);
    //     alert('Wallet connected');
    //   } catch (error) {
    //     console.error("Error connecting to wallet:", error);
    //   }
    // },

    // async connectWallet() {
    //     alert('Manual wallet connect');
    //     try {

    //         if (this.TONConnection) {
    //             alert('TON Connect UI initialized successfully');
    //         } else {
    //             alert('TON Connect UI failed to initialize');
    //         }

    //         const connectedWallet = await this.TONConnection.connectWallet();
    //         // Do something with connectedWallet if needed
    //         console.log("connectedWallet="+JSON.stringify(connectedWallet));
    //         alert(connectedWallet+"!");
    //         this.TON.wallet=connectedWallet;
    //     } catch (error) {
    //         alert("Error connecting to wallet:" + error);
    //     }
    // },

    doDEBUG() {
        this.DEBUG = !this.DEBUG;
        // alert('DEBUG = '+this.DEBUG);
    },
    

    // async sendTransaction() {
    //   const address = document.getElementById('address-input').value;
    //   const transaction = {
    //     validUntil: Math.floor(Date.now() / 1000) + 60,
    //     messages: [
    //       {
    //         address: address,
    //         amount: "100000000"
    //       }
    //     ]
    //   };

    //   try {
    //     const result = await this.TONConnection.sendTransaction(transaction, {
    //       modals: ['before', 'success', 'error'],
    //       notifications: ['before', 'success', 'error']
    //     });

    //     console.log('Transaction was sent successfully', result);
    //     alert('Transaction was sent successfully');
    //   } catch (e) {
    //     console.error('Transaction failed', e);
    //     alert('Transaction failed: ' + e.message);
    //   }
    // },

  }
};
</script>

<style>
@import "@/assets/index.css";
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
</style>
