<template>
    
    <!-- TOP MENU-->
    <div class="flex h-12 flex-row bg-gradient-to-b from-slate-700 to-slate-800 border-b border-slate-600 sticky top-0 z-50">
        <!-- shadow-slate-950 shadow-md -->
        <!-- <router-link :to="{ name: 'home' }" class="w-16 h-12 pt-2 pl-2"><img src="@/assets/logo/dtm.png" class="w-16 mx-auto"></router-link> -->

        <!-- <div class="w-full flex flex-row p-2 px-3 "> -->

            <router-link :to="{ name: 'home' }" class="ml-auto w-fit pt-1 my-auto shrink-0">
                <div v-if="Data.user_data.user_pic && Data.user_data.user_pic != 'NO'">
                    <img :src="getProfilePic(Data.user_data.user_pic)" class="ml-2 w-8 h-8 rounded-full mb-1 border border-slate-400">
                </div>
                <div v-else>
                    <img src="@/assets/icon/wallet.svg" class="ml-2 w-6 rounded-full mb-1">
                </div>
            </router-link>
            <div class="flex-grow ml-3 flex flex-col gap-0 min-w-10">
                <router-link :to="{ name: 'home' }" class="max-ms:hidden w-full">
                    <div class="text-sm mt-1 mb-0 font-bold flex flex-row gap-2">
                        <!-- <div class="h-6 text-nowrap overflow-hidden">{{ Data.user_data.user_name }}</div> -->
                        <div class="h-5 text-nowrap w-full overflow-hidden relative">
                            <div class="absolute right-0 w-8 h-full bg-gradient-to-r from-transparent to-slate-700 rounded-full z-10"></div>
                            {{ Data.user_data.user_name }}
                        </div>
                        <!-- <router-link :to="{ name: 'settings' }" class="my-auto"><img src="@/assets/icon/edit-w.svg" class="w-4 h-4 opacity-30"></router-link> -->
                    </div>
                    <!-- <div>
                        <div class="flex flex-row gap-2 text-sm my-auto">
                            <img src="@/assets/icon/gang.svg" class="w-4 h-4 mt-1">
                            <div class="font-bold text-base">{{ Data.user_data.user_gang }}</div>
                            <div class="but-6 but-a ml-auto my-auto">Recruit</div>
                        </div>
                    </div> -->
                    <div class="text-[11px] flex flex-row gap-1 mt-0 ">
                        <div class="shrink-0">LVL <b class="text-white">{{ Data.user_data.user_level }}</b></div>
                        <!-- <div class="shrink-0  text-slate-500 uppercase">EXP</div> -->
                        <div class="shrink-0  text-slate-400">({{ Data.user_data.user_exp }} / {{ Data.user_data.user_exp_next }})</div>
                    </div>
                </router-link>
            </div>

            <!-- <div class="shrink-0 flex flex-row"> -->
                <!-- <img src="@/assets/icon/pin.svg" class="w-4 h-4 mr-2 my-auto opacity-60 ml-3">
                <router-link :to="{ name: 'travel' }" class="text-sm text-slate-300 uppercase my-auto mr-2">
                    {{ Data.user_data.user_city_name }}
                </router-link>
                <router-link :to="{ name: 'rank' }" class="flex-grow shrink-0 flex flex-row mr-2">
                    <img src="@/assets/icon/cup.svg" class="w-4 h-4 mr-2 my-auto">
                    <div class="my-auto">{{ Data.user_data.user_city_stars }}</div>
                </router-link> -->
                <!-- <router-link :to="{ name: 'travel' }" class="ml-auto but-5 but-cc flex m-3 flex-row">
                    <img src="@/assets/icon/plane.svg" class="w-3 h-3 mr-2 my-auto opacity-70">
                </router-link> -->
                <!-- <div class="ml-auto but-5 but-c flex flex-row">
                    <img src="@/assets/icon/plane-b.svg" class="w-3 h-3 mr-2 my-auto">
                    <div class="">Travel</div>
                </div> -->
            <!-- </div> -->
        <!-- </div> -->


        <!-- <div class="uppercase my-auto ts1 text-slate-200 font-bold text-lg text-center"> -->
            
        <!-- </div> -->
        <!-- <div class="flex-grow"></div> -->
        <!-- <router-link :to="{ name: 'home', params: { 'scrollTo': '#collect' } }" class="bg-slate-200 w-fit mt-2 ml-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto flex flex-row gap-2"> -->
        <div class="ml-2 mt-0 shrink-0">
            <router-link :to="{ name: 'cash', hash: '#collect' }" @click.native="doHideCollect()" class="bg-slate-200 w-fit flex flex-row gap-2 mt-2 ml-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto whitespace-nowrap">
                <span v-if="updatingData">
                    <div class="spinner"></div>
                </span>
                <span class="" v-else>
                    <img src="@/assets/pics/cash.png" class="w-5 inline-block mr-2">
                    <span v-if="Data.user_data.user_collect_ready==1 && Data.user_data.user_profit>0 && !hideCollect" class="bg-green-700 bg-opacity-70 rounded-md px-1 py-0">+{{ Data.user_data.user_profit }}</span>
                    <span v-else>
                        <span class="text-white my-auto shrink-0">{{ Data.user_data.user_cash }}</span>   
                    </span>
                </span>
            </router-link>
        </div>
        <!-- <div class="">
            <router-link :to="{ name: 'profile' }" class="bg-slate-200 w-fit mt-2 ml-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto flex flex-row gap-2">
                    <img src="@/assets/icon/ton.svg" class="w-5">
                    <span class="text-white my-auto">{{ Data.user_data.user_ton }}</span>   
            </router-link>
        </div> -->
        <div class="mt-0 shrink-0">
            <router-link :to="{ name: 'shop' }" class="bg-slate-200 w-fit mt-2 ml-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto flex flex-row gap-2">
                <img src="@/assets/pics/gem.png" class="w-5">
                <span class="text-white my-auto">{{ Data.user_data.user_gems }}</span>
                <div class="bg-slate-100 rounded-sm p-1 flex flex-row w-4 h-4 my-auto max-xs:hidden shrink-0">
                    <img src="@/assets/icon/plus-b.svg" class="my-auto mx-auto">
                </div>
            </router-link>
        </div>
        <!-- <router-link :to="{ name: 'profile' }" class="ml-auto w-fit pt-2 max-xs:hidden"> -->
        <!-- <button @click="doUserProfile()" class="ml-auto w-fit pt-1">
            <div v-if="Data.user_data.user_pic && Data.user_data.user_pic != 'NO'">
                <img :src="getProfilePic(Data.user_data.user_pic)" class="ml-2 w-8 h-8 rounded-full mb-1 border border-slate-400">
            </div>
            <div v-else>
                <img src="@/assets/icon/wallet.svg" class="ml-2 w-6 rounded-full mb-1">
            </div>
        </button> -->
        <!-- </router-link> -->
        <div class="shrink-0 mt-0 ml-0 mr-0 w-fit px-2 pr-3 pt-3" @click="doMenu()"><img src="@/assets/icon/menu.svg" class="h-6 w-6 cursor-pointer"></div>

    </div>
    
</template>

<script>

export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
        TutorialShow: Boolean,
        updatingData: Boolean,
    },
    data() {
        return {
            hideCollect: false,
        }
    },
    mounted() {
        // this.Data.user_data.user_collect_ready = true; // WTF???
    },
    methods: {
        doHideCollect() {
            this.hideCollect = true;
        },
        doMenu() {
            if(this.TutorialShow) this.$emit('doTutorial', 9);
            this.$emit('doMenu');
        },
        doUserProfile() {
            this.$emit('doUserProfile');
        },
    }
}

</script>