<template>
    <div class="
    md:w-[80%] md:max-w-[700px] mx-auto
    border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl fixed top-12 mt-1 z-[45] rounded-md left-3 right-3">
        <div class="flex flex-row gap-2 p-3">

            <div class="my-auto"><img src="@/assets/icon/flag.svg" class="w-5 h-5"></div>
            <div class="my-auto">New achievement <img src="@/assets/pics/cash.png" class="w-5 inline-block mb-1"> Cash reward!</div>
            <button class="ml-auto but-4 but-c my-auto" @click="goAchieve">Claim</button>
            <button class="but-5 but-b my-auto" @click="closeAchieve">X</button>
            <!-- <router-link :to="{ name: 'levelup' }" class="ml-auto but-4 but-c" @click="closeAchieve">Level Up</router-link> -->
        </div>
    </div> 
</template>

<script>
  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
    },
    data() {
        return {

        }
    },
    methods: {
        goAchieve() {
            this.$router.push({ name: 'achieve' });
            this.$emit('closeAchieve');
        },
        closeAchieve() {
            this.$emit('closeAchieve');
        }
    },
    mounted() {
        // this.Data.user_data = this.Data.user_data;
    },
}
</script>