<template>
  
    <div class="z-[55] fixed p-4 inset-3 overflow-y-auto border backdrop-blur-sm border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-2xl rounded-xl">
        <div class="flex flex-row gap-3">
            <h5 class="mb-2">Welcome</h5>
            <button class="ml-auto but-5 but-b top-5 right-5 opacity-50" @click="closeModal()">X</button>
        </div>
        <div class="flex flex-col gap-2">



            <div class="mt-2">
                <img src="@/assets/pics/welcome.png" class="w-[80%] mx-auto">
            </div>

            <div class="mt-2 text-center">
                <div class="text-xl mb-2">Build the most powerful<div class="max-xs:hidden"></div>mafia gang in Telegram!</div>
            </div>
            
            <div class="w-[80%] mx-auto flex flex-col gap-3">
                <div class="mt-2 flex flex-row-2 gap-5">
                    <!-- <div class="shrink-0"><img src="@/assets/set/radio.svg" class="w-10 h-10 mx-auto"></div> -->
                    <div class="shrink-0"><img src="@/assets/pics/tutor.png" class="w-10 h-10 mx-auto"></div>
                    <div>
                        <h2 class="text-lg mb-0">Recruit Gangsters</h2>
                        <div class="text-sm">
                            Equip them with Weapons 
                        </div>
                    </div>
                </div>
                <div class="mt-2 flex flex-row-2 gap-5">
                    <div class="shrink-0"><img src="@/assets/set/gun.svg" class="w-10 h-10 mx-auto"></div>
                    <div>
                        <h2 class="text-lg mb-0">Fight Rivals</h2>
                        <div class="text-sm">
                            Attack other gangs to win Cash, EXP and
                            <img src="@/assets/icon/cup.svg" class="w-4 h-4 inline-block"> Cups 
                            in every city.
                        </div>
                    </div>
                </div>
                <div class="mt-2 flex flex-row-2 gap-5">
                    <!-- <div class="shrink-0"><img src="@/assets/set/city.svg" class="w-10 h-10 mx-auto"></div> -->
                    <div class="shrink-0"><img src="@/assets/pics/don.png" class="w-10 h-10 mx-auto"></div>
                    <div>
                        <h2 class="text-lg mb-0">Claim $DON</h2>
                        <div class="text-sm">
                            Exchange <img src="@/assets/icon/cup.svg" class="w-4 h-4 inline-block"> Cups 
                            for $DON at the end of each Season.
                        </div>
                    </div>
                </div>
            </div>

            <div class="mx-auto mt-4">
                <button @click="closeModal()" class="but-2 but-a w-fit">Start!</button>
            </div>

            <div class="text-sm text-slate-400">


            </div>

        </div>

    </div>

</template>

<script>
import promoImage from '@/assets/promo/p1.jpg';
  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
    },
    data() {
        return {
            promoImage,
        }
    },
    methods: {
        goShop() {
            this.$router.push({ name: 'shop' });
            this.$emit('closeModal');
        },
        closeModal() {
            this.$emit('closeModal');
        }
    },
    mounted() {
       
    },
}
</script>