<template>
    <div class="bg-slate-800 border-b border-slate-600 shadow-slate-950 shadow-xl sticky top-[-8px] z-40">
        <!--  top-[24px] for V3 -->

        <!-- <div class="flex flex-row gap-2 border-b border-slate-600">
            <router-link :to="{ name: 'restore' }" 
            class="group cursor-pointer w-full p-2 flex flex-col">
                <div class="text-sm text-slate-500 uppercase flex flex-row gap-0">
                    <div class="w-1/3 shrink-0 mr-1">
                        <div class="text-xs flex flex-row gap-1">
                            <div class="shrink-0">LVL <b class="text-white">{{ Data.user_data.user_level }}</b></div>
                            <div class="shrink-0  text-slate-500 uppercase">EXP</div>
                            <div class="shrink-0  text-slate-400">{{ Data.user_data.user_exp }} / {{ Data.user_data.user_exp_next }}</div>
                        </div>
                    </div>
                    <div class="w-2/3">
                        <div class="my-1 flex items-center bg-gray-200 h-2 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <div class="bg-gradient-to-b from-blue-500 to-blue-800 h-full" :style="{ width: (Data.user_data.user_exp_num / Data.user_data.user_exp_next_num) * 100 + '%' }"></div>
                        </div>
                    </div>
                </div>
            </router-link>
        </div> -->

        <div class="flex flex-row gap-2 border-b border-slate-600 h-14">

            <div
            class="group w-1/3 px-2 py-1 flex flex-col border-r border-slate-600">
                <router-link :to="{ name: 'gang' }">
                    <div class="shrink-0 text-slate-500 text-[11px] uppercase mt-0">Gangsters</div>
                    <div class="text-sm my-auto flex flex-row gap-2 ">
                        <div class="flex flex-row gap-2 ">
                            <img src="@/assets/icon/gang.svg" class="w-4 h-4 mt-1">
                            <div class="font-bold text-base">{{ Data.user_data.user_gang }}</div>
                        </div>
                        <router-link :to="{ name: 'invite' }" class="but-6 but-a ml-auto my-auto">
                            <span class="max-xs:hidden">Recruit</span>
                            <span class="xs:hidden">+</span>
                        </router-link>
                    </div>
                </router-link>
  
            </div>

            <div
            class="group cursor-pointer flex-grow px-2 py-1 flex flex-col border-r border-slate-600">
                <router-link :to="{ name: 'cash', hash: '#collect' }">
                    <div class="shrink-0 text-slate-500 text-[11px] uppercase mt-0">Profit</div>
                    <div class="text-sm my-auto flex flex-row gap-2 ">
                        <!-- v-if="Data.user_data.user_collect_ready==1 && Data.user_data.user_profit>0 && !hideCollect"  -->

                        <div v-if="Data.user_data.user_profit_num>0" class="w-full">
                            <div v-if="Data.user_data.user_collect_ready==1" class="flex flex-row gap-2 w-full">
                                <div class="text-base flex flex-row gap-1 text-green-200 whitespace-nowrap max-ms:pt-1 max-ms:text-xs"><img src="@/assets/pics/cash.png" class="w-5">{{ Data.user_data.user_profit }}</div>
                                <!--  Ready to collect: -->
                                <!-- <div class="mb-2 pt-1 text-green-500"><img src="@/assets/icon/check.svg" class="w-3 mr-1 mb-1 inline-block"></div> -->
                                <!-- <button @click="doCollect()" class="but-4 but-c">Collect</button> -->
                                <div  class="but-6 but-a my-auto"><img src="@/assets/set/hand.svg" class="w-4 h-4"></div>
                            </div>
                            <div v-else class="flex flex-row gap-2 w-full">
                                <div class="mb-2 text-slate-400"><img src="@/assets/icon/time.svg" class="w-4 mr-2 inline-block">{{ timeRemaining }}</div> 
                                <!-- <div class="but-4 but-r opacity-35">Collect</div> -->
                                <!-- <button @click="doCollectNow()" class="but-4 but-a">Collect</button> -->
                                <div class="bg-white ml-auto bg-opacity-20 rounded-md px-2 py-1 mb-2 my-auto"><img src="@/assets/pics/gem.png" class="w-4 h-4"></div>
                            </div>
                        </div>
                        <div v-else class="mb-2 w-full">
                            <div v-if="Data.user_data.user_profit==0">
                                <!-- You have no profit to collect -->
                                <div class="text-sm flex flex-row gap-1 whitespace-nowrap"><img src="@/assets/pics/cash.png" class="w-5">{{ Data.user_data.user_profit }}</div>
                            </div>
                            <div v-else class="flex flex-row gap-2 justify-between w-full">
                                <!-- You have negative cash flow and not able to collect profit -->
                                <div class="text-sm flex flex-row gap-1 text-orange-400 whitespace-nowrap"><img src="@/assets/pics/cash.png" class="w-5">{{ Data.user_data.user_profit }}</div>
                            </div>
                        </div>


                        <!-- <span v-if="Data.user_data.user_collect_ready==1 && Data.user_data.user_profit>0"
                            class="bg-green-700 bg-opacity-70 rounded-md px-1 py-0">
                            <img src="@/assets/pics/cash.png" class="w-5 inline-block mr-2">
                            {{ Data.user_data.user_profit }}
                        </span>
                        <span v-else-if="Data.user_data.user_profit<0"
                            class="bg-orange-700 bg-opacity-70 rounded-md px-1 py-0">
                            <img src="@/assets/pics/cash.png" class="w-5 inline-block mr-2">
                            {{ Data.user_data.user_profit }}
                        </span>
                        <span v-else>
                            Ready in ...
                        </span> -->
                        <!-- <div class="but-6 but-a ml-auto my-auto">Collect</div> -->
                    </div>
                </router-link>
  
            </div>

            <div
            class="group cursor-pointer w-1/3 px-2 py-1 flex flex-col">
                <div>
                    <!-- <div class="shrink-0 text-slate-500 text-[11px] uppercase mt-0">Power</div> -->
                    <div class="shrink-0 text-slate-500 text-[11px] uppercase mt-0">Cups</div>
                    <div class="text-sm my-auto flex flex-row gap-2 ">
                        <div class="flex flex-row gap-1 mt-1">
                            <router-link :to="{ name: 'rank' }" class="ts-1 max-xs:hidden"><img src="@/assets/icon/cup.svg" class="max-ms:w-3 w-5 inline-block mr-2 mb-1"><span class="mr-2">&mdash;</span></router-link>
                            <router-link :to="{ name: 'rank' }" class="ts-1"><img src="@/assets/icon/cup-b.svg" class="max-ms:w-3 w-5 inline-block mr-2 mb-1"><span class="mr-2">{{ Data.user_data.user_city_stars }}</span></router-link>
                        </div>
                        <!-- <router-link :to="{ name: 'restore' }" class="flex flex-row gap-2 text-sm">

                            <img src="@/assets/pics/bang.png" class="w-4 h-4 mt-1">
                            <div class="text-white font-bold">{{ Data.user_data?.power?.dam }}</div>

                            
                        </router-link>
                        <div class="but-6 but-a ml-auto my-auto">Manage</div> -->
                        <!-- <div class=""><span class="text-slate-500 font-light">DAM</span> <b>{{ Data.user_data?.power?.dam }}</b></div> -->
                        <!-- <div class=""><span class="text-slate-500 font-light">DEF</span> <b>{{ Data.user_data?.power?.def }}</b></div> -->
                    </div>
                </div>
  
            </div>

            <!-- V4 -->
            <!-- <div
            class="group cursor-pointer w-1/3 px-2 py-1 flex flex-col border-r border-slate-600">
                <div>
                    <div class="shrink-0 text-slate-500 text-[11px] uppercase mt-0">Gangsters</div>
                    <div class="text-sm my-auto flex flex-row gap-2 ">
                        <router-link :to="{ name: 'restore' }" class="flex flex-row gap-2 ">
                            <img src="@/assets/icon/gang.svg" class="w-4 h-4 mt-1">
                            <div class="font-bold text-base">{{ Data.user_data.user_gang }}</div>
                        </router-link>
                        <div class="but-6 but-a ml-auto my-auto">Recruit</div>
                    </div>
                </div>
  
            </div>
            <div class="flex flex-col gap-1 justify-end flex-grow">

                <div class="w-1/5 mr-1">
                        <div class="text-xs flex flex-row gap-1">
                            <div class="shrink-0">Level <b class="text-white">{{ Data.user_data.user_level }}</b></div>
                        </div>
                    </div>
                    <div class="w-3/5 mr-3">
                        <div class="my-1 flex items-center bg-gray-200 h-2 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <div class="bg-gradient-to-b from-blue-500 to-blue-800 h-full" :style="{ width: (Data.user_data.user_exp_num / Data.user_data.user_exp_next_num) * 100 + '%' }"></div>
                        </div>
                    </div>
                    <div class="w-2/5 mr-1">
                        <div class="text-xs text-right flex flex-row gap-1 justify-end items-start">
                            <div class="shrink-0 text-slate-500 uppercase">EXP</div>
                            <div class="shrink-0 text-slate-400">{{ Data.user_data.user_exp }} / {{ Data.user_data.user_exp_next }}</div>
                        </div>
                    </div>

            </div> -->
            <!-- 
            Cash flow
            <div class="flex flex-row gap-2 justify-end flex-grow">
                <div class="mt-0 p-1 ">
                    <router-link :to="{ name: 'home', hash: '#collect' }" @click.native="doHideCollect()" class="bg-slate-200 w-fit flex flex-row gap-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto">
                        <span v-if="updatingData">
                            <div class="spinner"></div>
                        </span>
                        <span class="" v-else>
                            <img src="@/assets/pics/cash.png" class="w-5 inline-block mr-2">
                            <span>
                                <span class="text-white my-auto shrink-0">{{ formatNumber(Data.user_data.user_cash_full) }}</span>   
                            </span>
                        </span>
                        <span 
                            v-if="Data.user_data.user_collect_ready==1 && Data.user_data.user_profit>0 && !hideCollect" 
                            class="bg-green-700 bg-opacity-70 rounded-md px-1 py-0">
                            +{{ Data.user_data.user_profit }}
                        </span>

                    </router-link>
                </div>
                
            </div> -->
             <!-- //  V4 -->

            <!-- V3 EXP with BAR -->
            <!-- <router-link :to="{ name: 'restore' }" 
            class="group cursor-pointer flex-grow p-2 flex flex-col">
                <div class="text-sm text-slate-500 uppercase flex flex-row gap-0">
                    <div class="w-1/5 mr-1">
                        <div class="text-xs flex flex-row gap-1">
                            <div class="shrink-0">Level <b class="text-white">{{ Data.user_data.user_level }}</b></div>
                        </div>
                    </div>
                    <div class="w-3/5 mr-3">
                        <div class="my-1 flex items-center bg-gray-200 h-2 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <div class="bg-gradient-to-b from-blue-500 to-blue-800 h-full" :style="{ width: (Data.user_data.user_exp_num / Data.user_data.user_exp_next_num) * 100 + '%' }"></div>
                        </div>
                    </div>
                    <div class="w-2/5 mr-1">
                        <div class="text-xs text-right flex flex-row gap-1 justify-end items-start">
                            <div class="shrink-0 text-slate-500 uppercase">EXP</div>
                            <div class="shrink-0 text-slate-400">{{ Data.user_data.user_exp }} / {{ Data.user_data.user_exp_next }}</div>
                        </div>
                    </div>
                </div>
            </router-link> -->

        </div>
        
        <div class="flex flex-row gap-2 h-8">
            <router-link :to="{ name: 'restore' }" class="group cursor-pointer w-1/3 border-r border-slate-600 p-2 flex flex-col">
                <div class="text-sm text-slate-500 uppercase flex flex-row gap-0">
                    <div class="w-1/6 shrink-0 mr-1"><img src="@/assets/set/hp.svg" class="w-4 h-4"></div>
                    <div class="w-5/6">
                        <div class="flex items-center bg-gray-200 h-4 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <!-- <div class="bg-gradient-to-b from-red-500 to-red-800 h-full text-white text-right text-[11px] font-bold" :style="{ width: Data.user_data.user_health + '%' }"> -->
                            <div class="relative bg-gradient-to-b from-red-500 to-red-800 h-full text-[11px] font-bold" :style="{ width: Data.user_data.user_health + '%' }">
                                <!-- <div class=" right-0 mt-[-3px] pr-1 text-red-300">{{ Data.user_data.user_hp }} HP</div> -->
                                <div class="absolute left-0 mt-[-3px] text-left pl-1 text-red-200 mr-auto">{{ Data.user_data.user_health }}%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="text-sm text-slate-400 flex flex-row max-xs:hidden mt-2">
                    <div class="text-white text-[12px] font-bold">{{ Data.user_data.user_health }}%</div>
                    <div v-if="Data.user_data.user_health<100" class="ml-auto but-6 but-r group-hover:opacity-85">Restore</div>
                    <div v-else class="ml-auto but-6 but-b opacity-50 group-hover:opacity-85">Restore</div>
                </div> -->
            </router-link>
            <router-link :to="{ name: 'jobs' }" class="group cursor-pointer flex-grow border-r border-slate-600 p-2 flex flex-col">
                <div class="text-sm text-slate-500 uppercase flex flex-row gap-0">
                    <div class="w-1/6 shrink-0 mr-1"><img src="@/assets/set/energy.svg" class="w-5 h-5"></div>
                    <div class="w-5/6">
                        <div class="flex items-center bg-gray-200 h-4 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <div class="relative bg-gradient-to-b from-yellow-500 to-yellow-800 h-full text-[11px] font-bold" :style="{ width: (Data.user_data.user_energy / Data.user_data.user_energy_max) * 100 + '%' }">
                                <div class=" absolute left-0 mt-[-3px] text-left pl-1 text-yellow-200 mr-auto">{{ Data.user_data.user_energy }} / {{ Data.user_data.user_energy_max }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="text-sm text-slate-400 flex flex-row max-xs:hidden mt-1">
                    <div class="text-white text-[12px] font-bold">{{ Data.user_data.user_energy }} / {{ Data.user_data.user_energy_max }}</div>
                    <div class="ml-auto but-6 but-b opacity-50 group-hover:opacity-85">Jobs</div>
                </div> -->
            </router-link>
            <router-link :to="{ name: 'fight' }"  class="group cursor-pointer w-1/3 p-2 flex flex-col">
                <div class="text-sm text-slate-500 uppercase flex flex-row gap-2">
                    <div class="w-1/6 shrink-0"><img src="@/assets/set/gun.svg" class="w-5 h-5"></div>
                    <div class="w-5/6">
                        <div class="flex items-center bg-gray-200 h-4 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <div class="relative bg-gradient-to-b from-orange-500 to-orange-800 h-full text-[11px] font-bold" :style="{ width: (Data.user_data.user_stamina / Data.user_data.user_stamina_max) * 100 + '%' }">
                                <div class="absolute left-0 mt-[-3px] text-left pl-1 text-orange-200 mr-auto">{{ Data.user_data.user_stamina }} / {{ Data.user_data.user_stamina_max }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="text-sm text-slate-400 flex flex-row max-xs:hidden mt-1">
                    <div class="text-white text-[12px] font-bold">{{ Data.user_data.user_stamina }} / {{ Data.user_data.user_stamina_max }}</div>
                    <div class="ml-auto but-6 but-b opacity-50 group-hover:opacity-85">Attack</div>
                </div> -->
            </router-link>
        </div>
    </div> 
</template>

<script>

  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
    },
    data() {
        return {
            userData: {},
            countdownTimer: null,
            timeRemaining: '',
            remainingSeconds: 0
        }
    },
    mounted() {
        this.userData = this.Data.user_data;
        this.startCountdown();
       
    },
    methods: {
        startCountdown() {
            if (this.countdownTimer) {
                clearInterval(this.countdownTimer);
            }
            
            this.remainingSeconds = this.Data.user_data.user_collect_ready_in;
            
            const updateTimer = () => {
                if (this.remainingSeconds <= 0) {
                    clearInterval(this.countdownTimer);
                    this.timeRemaining = '00:00';
                    return;
                }

                this.remainingSeconds--;
                const minutes = Math.floor(this.remainingSeconds / 60);
                const seconds = this.remainingSeconds % 60;
                this.timeRemaining = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                if (this.remainingSeconds === 0) {
                    this.$emit('collection-ready');
                }
            }

            updateTimer();
            this.countdownTimer = setInterval(updateTimer, 1000);
        }
    },
    beforeUnmount() {
        if (this.countdownTimer) {
            clearInterval(this.countdownTimer);
        }
    },
    watch: {
        'Data.user_data.user_collect_ready_in'(newVal) {
            this.remainingSeconds = newVal;
            this.startCountdown();
        }
    }
}
</script>