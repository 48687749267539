import { createWebHistory, createRouter } from "vue-router";

const routes = [

    { // default page
      path: '/',
      redirect: { name: 'attack' }
    },

    // {
    //   path: '/:scrollTo?',
    //   name: 'home',
    //   component: () => import('@/views/Home.vue'),
    //   props: true 
    // },

    // { path: '/armory', name: 'armory', component: () => import('@/views/Armory/Index.vue'), props: true, 
    //   children: [
    //     { path: '/armory', name: 'armory', component: () => import('@/views/Armory/Armory.vue'), props: true },
    //     { path: '/weapons', name: 'weapons', component: () => import('@/views/Armory/Weapons.vue'), props: true },
    //     { path: '/power', name: 'power', component: () => import('@/views/Armory/Power.vue'), props: true },
    //     { path: '/craft', name: 'craft', component: () => import('@/views/Armory/Craft.vue'), props: true },
    //     { path: '/power', name: 'power', component: () => import('@/views/Armory/Power.vue'), props: true },
    //   ],
    //   redirect: { name: 'armory' }
    // },

    // { path: '/home', name: 'home', component: () => import('@/views/City/Home.vue'), props: true, },

    { path: '/cash', name: 'cash', component: () => import('@/views/Bank/Cash.vue'), props: true },

    { path: '/shop', name: 'shop', component: () => import('@/views/Bank/Shop.vue'), props: true },
    { path: '/boost', name: 'boost', component: () => import('@/views/Bank/Boost.vue'), props: true },
    { path: '/vip', name: 'vip', component: () => import('@/views/Bank/Vip.vue'), props: true },
    { path: '/restore', name: 'restore', component: () => import('@/views/Bank/Restore.vue'), props: true },

    { path: '/city', name: 'homcitye', component: () => import('@/views/City/Index.vue'), props: true, 
      children: [
        { path: 'home', alias: '/:scrollTo?', name: 'home', component: () => import('@/views/City/Home.vue'), props: true },
        { path: 'armory', name: 'armory', component: () => import('@/views/City/Armory.vue'), props: true },
        { path: 'weapons', name: 'weapons', component: () => import('@/views/Armory/Weapons.vue'), props: true },
        { path: 'power', name: 'power', component: () => import('@/views/Armory/Power.vue'), props: true },
        { path: 'estate', name: 'estate', component: () => import('@/views/City/Estate.vue'), props: true },
        { path: 'travel', name: 'travel', component: () => import('@/views/City/Travel.vue'), props: true },
      ],
      redirect: { name: 'home' }
    },
    { path: '/gang', name: 'gang', component: () => import('@/views/Gang/Index.vue'), props: true, 
      children: [
        { path: '/gangsters', name: 'gangsters', component: () => import('@/views/Gang/Gangsters.vue'), props: true },
        { path: '/train', name: 'train', component: () => import('@/views/Gang/Train.vue'), props: true },
        { path: '/invite', name: 'invite', component: () => import('@/views/Gang/Recruit.vue'), props: true },
        { path: '/merc', name: 'merc', component: () => import('@/views/Gang/Hire.vue'), props: true },
      ],
      redirect: { name: 'gangsters' }
    },
    { path: '/attack', name: 'attack', component: () => import('@/views/Attack/Index.vue'), props: true, 
      children: [
        { path: '/fight:gangCode?', name: 'fight', component: () => import('@/views/Attack/Fight.vue'), props: true },
        { path: '/code', name: 'code', component: () => import('@/views/Attack/Code.vue'), props: true },
        { path: '/report', name: 'report', component: () => import('@/views/Attack/History.vue'), props: true },
        { path: '/button', name: 'button', component: () => import('@/views/Attack/Attack.vue'), props: true },
      ],
      redirect: { name: 'button' }
    },

    // { path: '/weapons', name: 'weapons', component: () => import('@/views/Weapons.vue'), props: true },
    // { path: '/power', name: 'power', component: () => import('@/views/Power.vue'), props: true },
   
    
    { path: '/jobs', name: 'jobs', component: () => import('@/views/Jobs/Index.vue'), props: true,
      children: [
        { path: '/missions', name: 'missions', component: () => import('@/views/Jobs/Missions.vue'), props: true },
        { path: '/achieve', name: 'achieve', component: () => import('@/views/Jobs/Achieve.vue'), props: true },
        // { path: '/daily', name: 'daily', component: () => import('@/views/Jobs/Daily.vue'), props: true },
        { path: '/tasks', name: 'tasks', component: () => import('@/views/Jobs/Tasks.vue'), props: true },

      ],
      redirect: { name: 'missions' }
    },

    { path: '/help', name: 'help', component: () => import('@/views/Help.vue'), props: true },
    { path: '/rank', name: 'rank', component: () => import('@/views/Rank.vue'), props: true },
    { path: '/settings', name: 'settings', component: () => import('@/views/Settings.vue'), props: true },

    // { path: '/train', name: 'train', component: () => import('@/views/Train.vue'), props: true },
    { path: '/levelup', name: 'levelup', component: () => import('@/views/LevelUp.vue'), props: true },
    { path: '/info', name: 'info', component: () => import('@/views/Info.vue'), props: true },
    { path: '/guide', name: 'guide', component: () => import('@/views/Guide.vue'), props: true, children: [
        { path: '', name: 'guide-intro', component: () => import('@/views/Guide/_Intro.vue') },
        { path: 'info', name: 'guide-info', component: () => import('@/views/Guide/Info.vue') },
        { path: 'gang', name: 'guide-gang', component: () => import('@/views/Guide/Gang.vue') },
        { path: 'merc', name: 'guide-merc', component: () => import('@/views/Guide/Merc.vue') },
        { path: 'weap', name: 'guide-weap', component: () => import('@/views/Guide/Weap.vue') },
        { path: 'prop', name: 'guide-prop', component: () => import('@/views/Guide/Prop.vue') },
        { path: 'fight', name: 'guide-fight', component: () => import('@/views/Guide/Fight.vue') },
        { path: 'travel', name: 'guide-travel', component: () => import('@/views/Guide/Travel.vue') },
        { path: 'jobs', name: 'guide-jobs', component: () => import('@/views/Guide/Jobs.vue') },
        { path: 'restart', name: 'guide-restart', component: () => import('@/views/Guide/Restart.vue') },
        { path: 'cash', name: 'guide-cash', component: () => import('@/views/Guide/Cash.vue') },
        { path: 'roadmap', name: 'guide-roadmap', component: () => import('@/views/Guide/Roadmap.vue') },
    ] },
    
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            const element = document.querySelector(to.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            return { top: 0 }
        }
    },
  });
  
//   router.beforeEach((to, from, next) => {
//     const publicPages = [
//         '/', 
//         // '/wiz', '/wiz/2','/wiz/2','/wiz/3','/wiz/4','/wiz/5', // TEMP!
//         '/auth', '/logout' // , '/signin1', '/signup', '/signup1'
//     ]; //  '/home'
//     const authRequired = !publicPages.includes(to.path);
//     const loggedIn = localStorage.getItem('user');
//     // trying to access a restricted page + not logged in
//     // redirect to login page
//     if (authRequired && !loggedIn) {
//       next('/auth');
//     } else {
//       next();
//     }
//   });
  
  export default router;
