<template>

    <div class="relative h-32">
        <div class="absolute h-32 inset-0 bg-gradient-to-t from-slate-900 to-transparent pt-4 px-4">
            <!-- pt-20 -->
            <div class="flex flex-row ">
                <div class="flex flex-col mt-0">
                    <h4 class="text-base text-white font-bold ts-1"><img src="@/assets/icon/pin.svg" class="inline-block mb-1 w-4 mr-2"><b>{{ Data.user_data.user_city_name }}</b> city</h4>
                    <div class="text-sm text-orange-300 mt-1 ts-2">
                        Pre Season &mdash; Week #{{ currentWeekNumber }}
                        </div>
                    <!-- <div class="flex flex-row gap-1 mt-1">
                        <router-link :to="{ name: 'rank' }" class="ts-1"><img src="@/assets/icon/cup.svg" class="w-5 inline-block mr-2 mb-1"><span class="mr-2">{{ Data.user_data.user_city_stars }}</span></router-link>
                        <router-link :to="{ name: 'rank' }" class="ts-1"><img src="@/assets/icon/cup-b.svg" class="w-5 inline-block mr-2 mb-1"><span class="mr-2">{{ Data.user_data.user_city_stars }}</span></router-link>
                    </div> -->
                </div>
                <div class="ml-auto">
                    <div>
                        <router-link :to="{ name: 'travel' }" class="but-5 but-cc flex flex-row">
                            <img src="@/assets/icon/plane.svg" class="w-3 h-3 mr-2 my-auto opacity-70">
                            <div class="">Travel</div>
                        </router-link>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            

        </div>
        <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${cityImage})` }"></div>
    </div>

</template>

<script>

export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
    },
    data() {
        return {
            userData: {},
            cityImage: '',
            currentWeekNumber: "-",
        };
    },
    mounted() {

        this.calculateCurrentWeek();

        this.userData = this.Data.user_data;
        this.cityImage = require(`@/assets/bg/city${this.Data.user_data.user_city}.jpg`);

    },
    methods: {

        calculateCurrentWeek() {
        const currentDate = new Date();
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const days = Math.floor((currentDate - startOfYear) / (24 * 60 * 60 * 1000));
        
        // Calculate the week number
        this.currentWeekNumber = Math.ceil((days + startOfYear.getDay() + 1) / 7);

        // Adjust for the case where the first days of the year are in the last week of the previous year
        if (startOfYear.getDay() > 4) {
            this.currentWeekNumber -= 1;
        }

        // Get the start and end dates of the current week
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        // Format dates
        const formatDate = (date) => {
            return date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' });
        };

        this.currentWeekRange = `${formatDate(startOfWeek)} to ${formatDate(endOfWeek)}`;
        },
        
    },
};

</script>